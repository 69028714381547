module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","lang":"el","name":"DrDiamantopoulos","short_name":"DrDiamantopoulos","description":"Ο πλαστικός χειρουργός Διαμαντόπουλος Παντελής διαθέτει αξιόλογη εμπειρία στην αισθητική και επανορθωτική πλαστική χειρουργική και καλείται να δώσει εξατομικευμένες λύσεις ανάλογα με τις εκάστοτε ανάγκες των ασθενών.","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"./src/images/icon-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c5c3010e51331a79b1cb9ae05a6befa1"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
