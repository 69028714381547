exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../../../src/templates/AboutPage.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-all-posts-jsx": () => import("./../../../src/templates/AllPosts.jsx" /* webpackChunkName: "component---src-templates-all-posts-jsx" */),
  "component---src-templates-case-studies-jsx": () => import("./../../../src/templates/CaseStudies.jsx" /* webpackChunkName: "component---src-templates-case-studies-jsx" */),
  "component---src-templates-clinic-jsx": () => import("./../../../src/templates/Clinic.jsx" /* webpackChunkName: "component---src-templates-clinic-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/HomePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-policy-jsx": () => import("./../../../src/templates/Policy.jsx" /* webpackChunkName: "component---src-templates-policy-jsx" */),
  "component---src-templates-posts-jsx": () => import("./../../../src/templates/Posts.jsx" /* webpackChunkName: "component---src-templates-posts-jsx" */),
  "component---src-templates-services-jsx": () => import("./../../../src/templates/Services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */)
}

